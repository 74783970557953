import React from 'react';
import { images } from '../assets/imageData';
import { useTheme } from '@emotion/react';
import { Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function LandingPage() {
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className='h-screen'
    >


      {/* <div className="flex justify-center items-center p-0 md:p-10">
        {images.home_banner ?
          <img src={images.home_banner} className="mx-auto" alt="Banner" />
          :
          <Skeleton />
        }
      </div> */}
      {/* <div className="text-center p-0 text-6xl mt-2 mb-4 lg:text-9xl" style={{ fontSize: isMobile ? "4rem" : "15rem" }}>
        EVAHAUS
      </div> */}
      <div className="flex justify-center my-6" style={{marginTop:"5rem"}}>
        <img
          src={images.home_logo3}
          className="h-10 lg:h-40 md:h-20"
        />
      </div>
      <hr className="border-1 border-black mt-10 mb-20 mx-20" />



      <div className="flex justify-center items-center px-10 md:px-40 lg:px-80">
        <Typography
          variant={isMobile ? 'small' : 'small_dt'}
          className={'italic indent-6'}
          sx={{
            '&::before': {
              content: '"“"',
              position: 'absolute',
              fontSize: '2em',
              lineHeight: '0.1em',
              left: '-1em',
              top: '-0.15em',
            },
            '&::after': {
              content: '"”"',
              position: 'absolute',
              fontSize: '2em',
              lineHeight: '0em',
              right: '-0.05em',
              bottom: '-0.25em',
            },
            position: 'relative',
          }}>
          We are a brand-building agency with a unique press representation. <br />We work with clients
          whose interests span across various aspects of the Fashion, {isMobile ? <br /> : <></>}
          Cosmetics and Hospitality with
          the focus on a strong digital and onlinepresence, utilising every relevant channel for their brand.
        </Typography>
      </div>
      <div className="flex justify-start  px-10 md:px-40 lg:px-80 mt-16 mb-20 cursor-pointer">
        <Typography variant='small' onClick={() => navigate("/ourservice")}>
          Our services &nbsp; {' >'}
        </Typography>
      </div>
    </motion.div>
  );
}
