import {
    Box,
    Grid,
    Skeleton,
    Divider,
    Backdrop,
    CircularProgress,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    ITFB_image, ITFB_carousel_1, ITFB_carousel_2, ITFB_carousel_3, ITFB_carousel_4, ITFB_carousel_5, ITFB_imageTop, ITFB_imageBig
} from "../../assets/imageData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselNew from "../../Components/Carousel";
import { motion } from 'framer-motion';

export default function ITFB() {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setImages(ITFB_imageTop);
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <Box
                p={{ xs: 2, md: 6 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                className=" "
            >
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box alignSelf="flex-start">
                    <Typography variant="h1">Our Works</Typography>
                </Box>
                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Content Production</Typography>
                    <Typography variant="medium_roboto">Matcha for Tom Ford Beauty</Typography>
                </Box>

                <Box>
                    <div className="mt-2">
                        <Grid container spacing={1} columns={12}>
                            <Grid item xs={3} sm={3} md={3}>
                                <img
                                    src={`${ITFB_image[0].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <img
                                    src={`${ITFB_image[1].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <img
                                    src={`${ITFB_image[2].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3}>
                                <img
                                    src={`${ITFB_image[3].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Box>

                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "3rem", marginBottom: "3rem", width: "100%" }}
                />
                <CarouselNew listmedia={ITFB_carousel_1} />

                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Content Production</Typography>
                    <Typography variant="medium_roboto">Porsche for Tom Ford Beauty</Typography>
                </Box>
                <Box>
                    <div className="mt-2 ">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box>
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={400}
                                        className="image-skeleton"
                                    />
                                    <img
                                        src={ITFB_imageBig[0].img}
                                        alt="topimg"
                                        loading="lazy"
                                        style={{ width: "100%", height: "auto" }}
                                        onLoad={(e) => {
                                            e.target.previousSibling.style.display = "none";
                                            e.target.style.display = "block";
                                        }}
                                        onError={(e) => {
                                            e.target.previousSibling.style.display = "none";
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>


                    <div className="mt-2">
                        <Grid container spacing={1} columns={12}>
                            {images
                                .filter((_, index) => index !== 9)
                                .map((item) => (
                                    <Grid item xs={3} sm={3} md={3}>
                                        <img
                                            src={`${item.img}`}
                                            style={{ width: "100%", height: "auto" }}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                </Box>
                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "3rem", marginBottom: "3rem", width: "100%" }}
                />

                <CarouselNew listmedia={ITFB_carousel_2} />


                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Content Production</Typography>
                    <Typography variant="medium_roboto">Koen for Tom Ford Beauty</Typography>
                </Box>
                <CarouselNew listmedia={ITFB_carousel_3} />


                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Content Production</Typography>
                    <Typography variant="medium_roboto">Dew for Tom Ford Beauty</Typography>
                </Box>
                <CarouselNew listmedia={ITFB_carousel_4} />


                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Content Production</Typography>
                    <Typography variant="medium_roboto">Jayler for Tom Ford Beauty</Typography>
                </Box>
                <CarouselNew listmedia={ITFB_carousel_5} />


            </Box>
        </motion.div>
    );
}
