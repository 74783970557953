import React from 'react'
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

export default function Contact() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const cols = matches ? 3 : 2;

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <div style={{ minHeight: matches ? "" : "45rem" }}>
                <Box p={{ xs: 2, md: 8 }} display='flex' flexDirection='column' alignItems='center'>
                    <Box alignSelf="flex-start">
                        <Typography variant='h1'>Contact</Typography>
                    </Box>
                    <Box alignSelf="flex-start" className="mt-14">
                        <Typography style={{fontFamily: "Baskerville" ,fontWeight:500, fontSize:30}}>
                            EVAHAUS
                        </Typography>
                    </Box>
                    <Box alignSelf="flex-start" className="mt-14">
                        <Typography variant='lg_roboto'>
                            We would love to work with  &nbsp;<span style={{fontFamily: "Baskerville" ,fontWeight:450, fontStyle: "italic"}}>You.</span><br></br>
                            Say &nbsp;<span style={{fontFamily: "Baskerville" ,fontWeight:450, fontStyle: "italic"}}>Hello!</span>

                        </Typography>
                    </Box>

                    <Box alignSelf="flex-start" className="mt-14">
                            <Typography variant="xs_roboto" gutterBottom  className='italic'> 
                                <b  style={{fontWeight:400}}>EMAIL</b>: business@evahaus.com
                                {<br />}
                            </Typography>
                            <Typography variant="xs_roboto" gutterBottom className='italic'>
                                <b  style={{fontWeight:400}}>TEL</b>: (+66) 65-636-6988
                                {<br />}
                            </Typography>
                            <Typography variant="xs_roboto" gutterBottom className='italic'> 
                                <b style={{fontWeight:400}}>LINE</b>: evahaus
                                {<br />}
                            </Typography>
                    </Box>


                </Box>
            </div>
        </motion.div>
    )
}
