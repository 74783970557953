import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from '../assets/imageData';
const drawerWidth = 240;
const navItems = [{ label: "HOME", link: "/" }, { label: "OUR SERVICES", link: "/ourservice" },
{ label: "OUR WORKS", link: "/ourwork" }, { label: "CONTACT", link: "/contact" }];

function DrawerAppBar(props) {
    const location = useLocation();
    const navigate = useNavigate()
    const theme = useTheme();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', background: theme.palette.secondary.main, color: "#FFFFFF" }}>
            {/* <Typography variant="h2" sx={{ my: 1 }}>
                EVAHAUS
            </Typography> */}
            <div className='px-8 my-6'>

                <img
                    src={images.home_logo2}
                />
            </div>
            <Divider />
            <List >
                {navItems.map((item) => (
                    <ListItem key={item.label} >
                        <ListItemButton sx={{
                            textAlign: 'center',
                            '&:hover': { textDecoration: 'underline', textDecorationColor: 'white' },
                            borderBottom: location.pathname === item.link ? '1px solid white' : 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }} onClick={() => navigate(item.link)}>
                            <Typography variant='small'>
                                {item.label}
                            </Typography>
                            <ChevronRightIcon sx={{ color: 'white' }} />
                        </ListItemButton>
                    </ListItem>



                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', marginBottom: "3.5rem" }}>
            <CssBaseline />
            <AppBar component="nav" sx={{
                background: theme.palette.background.default,
                boxShadow: 'none',
                borderBottom: `1px solid ${theme.palette.text.secondary}`,
            }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' }, color: theme.palette.primary.main }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography
                        variant="h2"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: theme.palette.primary.main }}
                    >
                        EVAHAUS
                    </Typography> */}
                    <Box className='px-8 '
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }}} >

                        <img
                            src={images.home_logo1}
                            style={{width:"10rem"}}
                            
                        />
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
                        {navItems.map((item) => (
                            <Button
                                key={item.label}
                                sx={{
                                    color: theme.palette.primary.main,
                                    marginRight: 5, '&:hover': { textDecoration: 'underline' },
                                    textDecoration: location.pathname === item.link ? 'underline' : 'none'
                                }}
                                onClick={() => navigate(item.link)}
                            >
                                <Typography
                                    variant='body1'
                                >
                                    {item.label}
                                </Typography>
                            </Button>


                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: theme.palette.secondary.main },

                    }}>
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}


export default DrawerAppBar;