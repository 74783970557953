import { theme } from "./Theme/customMUI";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import LandingPage from "./Pages/LandingPage";
import DrawerAppBar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Ourservice from "./Pages/Ourservice";
import Ourwork from "./Pages/Ourwork";
import Contact from "./Pages/Contact";
import Minnies from "./Pages/worksview/Minnies";
import Gidle from "./Pages/worksview/gidle";
import MLB from "./Pages/worksview/MLB";
import Gshock from "./Pages/worksview/Gshock";
import Jockey from "./Pages/worksview/jockey";
import Tiff1 from "./Pages/worksview/Tiff1";
import Tiff2 from "./Pages/worksview/Tiff2";
import TFB from "./Pages/worksview/Tfb";
import { AnimatePresence } from "framer-motion";
import ITFB from "./Pages/worksview/Itfb";
function App() {
  const location = useLocation;

  return (
    <ThemeProvider theme={theme} >
      <BrowserRouter>
        <DrawerAppBar />
        {/* <Routes location={location} key={location.pathname}> */}
        <Routes >
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/ourservice" element={<Ourservice />} />
          <Route path="/ourwork" element={<Ourwork />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/minnie" element={<Minnies />} />
          <Route path="/gidle" element={<Gidle />} />
          <Route path="/mlb" element={<MLB />} />
          <Route path="/gshock" element={<Gshock />} />
          <Route path="/jockey" element={<Jockey />} />
          <Route path="/Tiffany" element={<Tiff1 />} />
          <Route path="/Tiffany2" element={<Tiff2 />} />
          <Route path="/tfb" element={<TFB />} />
          <Route path="/itfb" element={<ITFB />} />
        </Routes>
        <AnimatePresence/>
        <Footer />
      </BrowserRouter>

    </ThemeProvider>
  );
}

export default App;
