
export const images = {
  home_banner: require('./images/Banner_frame.png'),
  home_logo1: require('./images/LandingBlackLogo.png'),
  home_logo2: require('./images/logosmall.png'),
  home_logo3: require('./images/logo eva haus-3.png')
};

export const ourwork_main_images = [
  {
    img: require("./images/ourwork_main/opt1.jpg"),
    title: "Artist Management",
    desc: "MINNIE (G)I-DLE for L'Officiel Thailand",
    link: "/minnie"
  },
  {
    img: require("./images/ourwork_main/opt2.png"),
    title: "Artist Management",
    desc: "YUQI & SHUHUA (G)I-DLE for SUDSAPDA",
    link: "/gidle"
  },
  {
    img: require("./images/ourwork_main/opt3.png"),
    title: "Influencer Campaign",
    desc: "G-SHOCK",
    link: "/gshock"
  },
  {
    img: require("./images/ourwork_main/opt4.png"),
    title: "Influencer Campaign",
    desc: "JOCKEY",
    link: "/jockey"
  },
  {
    img: require("./images/ourwork_main/opt5.png"),
    title: "Influencer Campaign",
    desc: "MLB",
    link: "/mlb"
    
  },
  {
    img: require("./images/ourwork_main/opt6.png"),
    title: "Influencer Campaign",
    desc: "Tiffany & Co.",
    link: "/Tiffany"

  },
  {
    img: require("./images/ourwork_main/opt7.png"),
    title: "Influencer Campaign",
    desc: "Tiffany & Co.",
    link: "/Tiffany2"
  },
  {
    img: require("./images/ourwork_main/opt8.png"),
    title: "Content Production",
    desc: "Tom Ford Beauty",
    link: "/tfb"
  },
  {
    img: require("./images/ourwork_main/mutcha.png"),
    title: "Content Production",
    desc: "Influencer Campaign for Tom Ford Beauty",
    link: "/itfb"
  },
];




export const minnie_image = [
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie1.png?t=5" },

  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie4.JPG?t=8" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie7.jpg?t=22" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie10.JPG" },

  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie5.JPG" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie8.jpg" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie11.JPG" },

  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie6.JPG" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie9.jpg" },
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie12.JPG" },

]

export const minnie_imageTop = [
  { img: require('./images/minnie/minnie2.png')},
  { img: require('./images/minnie/minnie3.png')},
]
export const minnie_imageBot = [
  { img: "https://firstdraw.blob.core.windows.net/evahaus/minnie13.jpg" },
  { img: require('./images/minnie/minnie14.JPG')},
]
export const minnie_carousel = [
  { url: "https://www.youtube.com/watch?v=NHPMfm2v5Ck", alt: "c1", type: "video" },
]

// -----------------
export const gidle_image = [
  { img: require("./images/gidle/gidle1.jpg") },

  { img: require("./images/gidle/gidle4.jpg") },
  { img: require("./images/gidle/gidle5.jpg") },
  { img: require("./images/gidle/gidle6.jpg") },
  { img: require("./images/gidle/gidle7.jpg") },
  { img: require("./images/gidle/gidle8.jpg") },
  { img: require("./images/gidle/gidle9.jpg") },
  { img: require("./images/gidle/gidle10.jpg") },
  { img: require("./images/gidle/gidle11.jpg") },
  { img: require("./images/gidle/gidle12.jpg") },
  { img: require("./images/gidle/gidle13.jpg") },
  { img: require("./images/gidle/gidle14.jpg") },
  { img: require("./images/gidle/gidle15.jpg") },
  { img: require("./images/gidle/gidle16.jpg") },
  { img: require("./images/gidle/gidle17.jpg") },
  { img: require("./images/gidle/gidle18.jpg") },

]
export const gidle_imageTop = [
  { img: require("./images/gidle/gidle2.jpg") },
  { img: require("./images/gidle/gidle3.jpg") },
]
export const gidle_imageBot = [
  { img: require("./images/gidle/gidle19.jpg") },
  { img: require("./images/gidle/gidle20.jpg") },
]
export const gidle_carousel = [
  { url: "https://www.youtube.com/watch?v=fn7RMJPx8ww", alt: "c1", type: "video" },
]


// ----------------- MLB
export const MLB_image = [
  { img: require("./images/MLB/MLB1.jpg") },

  { img: require("./images/MLB/MLB4.jpg") },
  { img: require("./images/MLB/MLB5.jpg") },
  { img: require("./images/MLB/MLB6.jpg") },
  { img: require("./images/MLB/MLB7.jpg") },
  { img: require("./images/MLB/MLB8.jpg") },
  { img: require("./images/MLB/MLB9.jpg") },
  { img: require("./images/MLB/MLB10.jpg") },
  { img: require("./images/MLB/MLB11.jpg") },
  { img: require("./images/MLB/MLB12.jpg") },
  { img: require("./images/MLB/MLB13.jpg") },
  { img: require("./images/MLB/MLB14.jpg") },
  { img: require("./images/MLB/MLB15.jpg") },
  { img: require("./images/MLB/MLB16.jpg") },
  { img: require("./images/MLB/MLB17.jpg") },
  { img: require("./images/MLB/MLB18.jpg") },
]
export const MLB_imageTop = [
  { img: require("./images/MLB/MLB2.jpg") },
  { img: require("./images/MLB/MLB3.jpg") },
]
export const MLB_imageBot = [
  { img: require("./images/MLB/MLB19.jpg") },
  { img: require("./images/MLB/MLB20.jpg") },
]
export const MLB_carousel = [
  { url: "https://firstdraw.blob.core.windows.net/evahaus/minnie11.JPG", alt: "c1", type: "image" },
]


// ----------------- Gshcok
export const gc_image = [
  { img: require("./images/gshock/gc1.jpg") },

  { img: require("./images/gshock/gc4.jpg") },
  { img: require("./images/gshock/gc5.jpg") },
  { img: require("./images/gshock/gc6.jpg") },
  { img: require("./images/gshock/gc7.jpg") },
  { img: require("./images/gshock/gc8.jpg") },
  { img: require("./images/gshock/gc9.jpg") },

]
export const gc_imageTop = [
  { img: require("./images/gshock/gc2.jpg") },
  { img: require("./images/gshock/gc3.jpg") },
]
export const gc_carousel = [
  { url: "https://firstdraw.blob.core.windows.net/evahaus/minnie11.JPG", alt: "c1", type: "image" },
]


// ----------------- jockey
export const Jockey_image = [
  { img: require("./images/Jockey/jockey (1).jpg") },
  
  { img: require("./images/Jockey/jockey (7).jpg") },
  { img: require("./images/Jockey/jockey (5).jpg") },
  { img: require("./images/Jockey/jockey (2).jpg") },
  { img: require("./images/Jockey/jockey (4).jpg") },
  { img: require("./images/Jockey/jockey (8).jpg") },
  { img: require("./images/Jockey/jockey (9).jpg") },

]
export const Jockey_imageTop = [
  { img: require("./images/Jockey/jockey (3).jpg") },
  { img: require("./images/Jockey/jockey (6).jpg") },

]
export const jockey_carousel = [
  { url: "https://firstdraw.blob.core.windows.net/evahaus/minnie11.JPG", alt: "c1", type: "image" },
]

// ----------------- tiff
export const Tiff1_image = [
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)1.jpg") },

  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)4.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)5.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)6.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)7.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)8.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)9.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)10.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)11.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)12.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)13.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)14.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)15.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)16.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)17.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)18.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)19.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)20.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)21.jpg") },
  
]
export const Tiff1_imageTop = [
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)2.jpg") },
  { img: require("./images/Tiffany&Co_1/Tiffany&Co(1)3.jpg") },


]
// ----------------- tiff2
export const Tiff2_image = [
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)1.jpeg") },

  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)4.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)5.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)6.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)7.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)8.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)9.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)10.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)11.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)12.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)13.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)14.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)15.PNG") },
  
]
export const Tiff2_imageTop = [
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)2.jpeg") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)3.jpeg") },
]
export const Tiff2_imageBot = [
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)16.PNG") },
  { img: require("./images/Tiffany&Co_2/Tiffany&Co(2)17.jpeg") },
]


// -------------------- tfb
export const TFB_image = [
  { img: require("./images/TFB/tfb1.jpg") },

  { img: require("./images/TFB/tfb4.jpg") },
  { img: require("./images/TFB/tfb5.jpg") },
  { img: require("./images/TFB/tfb6.jpg") },
  { img: require("./images/TFB/tfb7.jpg") },

]

export const TFB_imageTop = [
  { img: require("./images/TFB/tfb2.jpg") },
  { img: require("./images/TFB/tfb3.jpg") }
]
export const TFB_carousel = [
  { url: "https://www.youtube.com/watch?v=1Rsnytrwd5Q", alt: "c1", type: "video" },
  { url: "https://www.youtube.com/watch?v=gu6ROUomGrk", alt: "c2", type: "video" },
]


// -------------------- Itfb
export const ITFB_image = [
  { img: require("./images/itfb/TomFord_MATCHA-0198_02-min.jpg") },
  { img: require("./images/itfb/TomFord_MATCHA-0203_02-min.jpg") },
  { img: require("./images/itfb/TomFord_MATCHA-0205_02-min.jpg") },
  { img: require("./images/itfb/TomFord_MATCHA-0215_02-min.jpg") },
  { img: require("./images/itfb/TomFord_MATCHA-0380_02-min.jpg") },
  { img: require("./images/itfb/TomFord_MATCHA-0400_02-min.jpg") },
]

export const ITFB_imageBig = [
  { img: require("./images/itfb/porsche.sivk-148+-min.jpg") },

]
export const ITFB_imageTop = [
  { img: require("./images/itfb/porsche.sivk-009+-min.jpg") },
  { img: require("./images/itfb/porsche.sivk-105+-min.jpg") },
  { img: require("./images/itfb/porsche.sivk-158+-min.jpg") },
  { img: require("./images/itfb/porsche.sivk-180+-min.jpg") },
]
export const ITFB_carousel_1 = [
  { url: "https://www.youtube.com/watch?v=ulaziDN5zT4", alt: "c1", type: "video" },
]
export const ITFB_carousel_2 = [
  { url: "https://www.youtube.com/watch?v=ASSIpyg9LQU", alt: "c2", type: "video" },
]
export const ITFB_carousel_3 = [
  { url: "https://www.youtube.com/watch?v=InySJ4g-CU4", alt: "c3", type: "video" },
]
export const ITFB_carousel_4 = [
  { url: "https://www.youtube.com/watch?v=aJkAHKVZZLg", alt: "c4", type: "video" },
]
export const ITFB_carousel_5 = [
  { url: "https://www.youtube.com/watch?v=ArfwWX5rNto", alt: "c5", type: "video" },
]

