import React from 'react'
import { Box, Divider, ImageListItem, Typography, ImageList, useMediaQuery, useTheme } from '@mui/material';
import { ourwork_main_images } from '../assets/imageData';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
export default function Ourwork() {
    const navigate = useNavigate()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const cols = matches ? 3 : 2;

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <Box p={{ xs: 2, md: 10 }} display='flex' flexDirection='column' alignItems='center'>
                <Box alignSelf="flex-start">
                    <Typography variant='h1'>Our Works</Typography>
                    <Typography
                        variant='medium_roboto'
                        sx={{ fontWeight: 'thin', mt: 2 }}
                        align='left'
                    >
                        {/* Don’t Fall Short */}
                    </Typography>
                </Box>
                <Divider orientation="horizontal" style={{ marginTop: "2rem", width: '100%' , marginBottom:"2rem"}} sx={{ borderColor: "black" }} />

                <Box display='flex' justifyContent='center' alignItems='center' width='100%' overflow='hidden'>
                    <ImageList cols={cols} gap={16}>
                        {ourwork_main_images.map((item) => (
                            <div className='cursor-pointer' onClick={() => navigate(item.link)}>
                                <ImageListItem key={item.img} className='xs:p-0 lg:p-8'>
                                    <img
                                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.title}
                                        loading="lazy"
                                        className='workImg transform transition-transform duration-500 ease-in-out hover:scale-105 rounded'
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                    <Box className="xs:mt-2 lg:mt-4">
                                        <Typography variant={matches ? 'medium' : 'mbTitle'} sx={{ lineHeight: 0.4 }}>{item.title}</Typography><br />
                                        <Typography variant={matches ? 'small_roboto' : 'mbDesc'} style={{ lineHeight: 0.4 }}>{item.desc}</Typography>

                                    </Box>

                                </ImageListItem>
                            </div>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </motion.div>
    )
}
