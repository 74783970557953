import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";
import { minnie_carousel } from "../assets/imageData";
import "./styles.css";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

export default function CarouselNew({listmedia}) {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const h = matches ? "500px" : "200px"
    return (
        <>
            <Swiper
                navigation={true}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                className="mySwiper">
                {listmedia.map((item) => {
                    if (item.type === 'image') {
                        return (
                            <SwiperSlide key={item.url}>
                                <img src={item.url} alt={item.alt} className='lg:w-1/3 w-2/4' />
                            </SwiperSlide>
                        );
                    } else if (item.type === 'video') {
                        return (
                            <SwiperSlide key={item.url}>
                                <iframe
                                    style={{ width: '60%', height: h }}
                                    src={item.url.replace("watch?v=", "embed/")}
                                    alt={item.alt}
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                />

                            </SwiperSlide>
                        );
                    }
                })}
            </Swiper>
        </>
    );
}
