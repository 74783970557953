import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const footerItem = [{ label: "HOME", link: "/" }, { label: "OUR SERVICES", link: "/ourservice" },
{ label: "OUR WORKS", link: "/ourwork" }, { label: "CONTACT", link: "/contact" }]

export default function Footer() {
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className='align-bottom'>
            {!isMobile ? (
                <Box sx={{ width: '100%', backgroundColor: 'white', p: 2 }}>
                    {/* <hr className="border border-gray-300 mx-20 mb-10" /> */}
      <hr className="border-1 border-black mx-20 mb-10" />
                    

                    <Typography variant="h2" align="center" gutterBottom color="theme.palette.primary.main">
                        EVAHAUS
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.primary.main }}>
                        {footerItem.map((item) => (
                            <div className='mx-4'>
                                <Button key={item.label}>
                                    <Typography
                                        variant="body1"
                                        className='cursor-pointer'
                                        sx={{'&:hover': { textDecoration: 'underline' } ,
                                        textDecoration: location.pathname === item.link ? 'underline' : 'none'}}
                                        onClick={() => navigate(item.link)} >{item.label}</Typography>
                                </Button>
                            </div>
                        ))}
                    </Box>
                </Box>
            ) : (

                <Box sx={{ width: '100%', backgroundColor: 'white', p: 2 }}>
                    <Typography variant='body1' className='text-center'>
                        © 2023 all rights reserved, Eva Haus Co., Ltd.
                    </Typography>
                </Box>
            )}
        </div>
    );
}
