import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
export default function Ourservice() {
    const variantMapping = {
        'xs_roboto': 600,
        'md_roboto': 900,
        'lg_roboto': 1200
    };

    const [variant, setVariant] = useState('lg_roboto');

    useEffect(() => {
        function handleResize() {
            let selectedVariant = 'lg_roboto';
            for (const [key, value] of Object.entries(variantMapping)) {
                if (window.innerWidth <= value) {
                    selectedVariant = key;
                    break;
                }
            }
            setVariant(selectedVariant);
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <Box p={{ xs: 2, md: 10 }} display='flex' flexDirection='column'>
                <Box alignSelf="flex-start">
                    <Typography variant='h1'>Our Brands</Typography>
                    <Typography
                        variant='medium_roboto'
                        sx={{ fontWeight: 'thin', mt: 2 }}
                        align='left'
                    >
                        Don’t Fall Short
                    </Typography>
                </Box>
                <Grid container spacing={2} mt={4} columns={{ xs: 4, sm: 8, md: 13 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box mb={4}>
                            <div className='text-justify'>
                                <Typography variant={variant}>
                                    We work with clients whose interests span various aspects
                                    of the fashion, beauty and hospitality industry, utilising
                                    every relevant channel for their brand. Our focus are digital
                                    brands of brands that aim for a more digital and online presence.
                                    Ensuring our clients expectations are fulfilled, by providing a
                                    personal, creative and efficient service, with excellent
                                    international press and influencer affiliations.
                                </Typography>
                            </div>

                        </Box>
                        <Box mb={4}>
                            <div className='text-justify'>
                                <Typography variant={variant}>
                                    An important pillar is the digital highway, which leads directly to
                                    our house. We were the first to build a community of hundreds of
                                    models and influencer and today we are socially innovative.
                                    We believe in community and community support, which is a very
                                    important asset to the success of a brand’s growth.
                                </Typography>
                            </div>
                        </Box>
                        <Box mb={4}>
                            <div className='text-justify'>
                                <Typography variant={variant}>
                                    We work hard to keep our clients labels relevant and aspirational.
                                    EVAHAUS is built with integrity and belief in the aims and
                                    ambitions of each individual client.
                                </Typography>
                            </div>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                        <Divider
                            orientation="vertical"
                            className='mr-0 ml-0'
                            variant="middle" sx={{ height: '100%', display: { xs: 'none', sm: 'block' }, borderColor: "black" }} />
                        <Divider
                        className='mx-10'
                            orientation="horizontal"
                            variant="
                            " sx={{ width: '100%', display: { xs: 'block', sm: 'none' }, borderColor: "black" }} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} display='flex' flexDirection='column' alignItems='left' justifyContent='start'>
                        <div className='ml-0 lg:ml-48'>
                            <Box mb={4}>
                                <Typography variant='h2'>
                                    Our Services
                                </Typography>
                            </Box>
                            <Typography variant={variant}>
                                <p className='mb-4'>Influencer Campaign</p>
                                <p className='mb-4'>KOLs Management</p>
                                <p className='mb-4'>Digital PR</p>
                                <p className='mb-4'>Public Relation</p>
                                <p className='mb-4'>Events</p>
                                <p className='mb-4'>Content Creation and Production</p>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </motion.div>
    );
}
