import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2D2D2D',
    },
    secondary: {
      main: '#000',
    },
    text: {
      primary: '#2D2D2D',
      secondary: '#f0f0f0',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Baskervville", sans-serif',
    h1: {
      fontSize: 52,
      fontWeight: '200',
      fontFamily: 'Baskervville',
    },
    h2: {
      fontSize: 32,
      fontWeight: '200',
      fontFamily: 'Baskervville',
    },
    paragraph: {
      fontSize: 26,
      fontWeight:'100', 
      fontFamily: 'Roboto  ',
    },
    paragraph2: {
      fontSize: 24,
      fontWeight:'100', 
      fontFamily: 'Roboto  ',
    },
    paragraphSmall: {
      fontSize: 14,
      fontWeight: '200', 
      fontFamily: 'Roboto',
    },
    medium: {
      fontSize: 22,
      fontWeight: '200',
      fontFamily: 'Baskervville',
    },
    medium_roboto: {
      fontSize: 20,
      fontWeight: '200',
      fontFamily: 'Roboto ',
    },
    lg_roboto: {
      fontSize: 20,
      fontWeight: '200',
      fontFamily: 'Roboto ',
    },
    xs_roboto: {
      fontSize: 16,
      fontWeight: '100',
      fontFamily: 'Roboto',
    },
    small_dt: {
      fontSize: 20,
      fontWeight:"100",
      fontFamily: 'Roboto ',
    },
    small: {
      fontSize: 16,
      fontWeight: '200',
      fontFamily: 'Roboto ',
    },
    small_roboto: {
      fontSize: 16,
      fontWeight: '200',
      fontFamily: 'Roboto  ',
    },
    body1: {
      fontSize: 14,
      fontWeight: '200',
      fontFamily: 'Baskervville',
    },

    mbTitle: {
      fontSize: 14,
      fontWeight: '200',
      fontFamily: 'Baskervville',
    },
    mbDesc: {
      fontSize: 12,
      fontWeight: '200',
      fontFamily: 'Roboto  ',
    },
  },
});