import {
    Box,
    Grid,
    Skeleton,
    Divider,
    Backdrop,
    CircularProgress,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Jockey_image, Jockey_imageTop } from "../../assets/imageData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion } from 'framer-motion';

export default function Jockey() {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setImages(Jockey_image);
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <Box
                p={{ xs: 2, md: 6 }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                className=" "
            >
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box alignSelf="flex-start">
                    <Typography variant="h1">Our Works</Typography>
                </Box>
                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
                />

                <Box alignSelf="flex-start" className="mb-4">
                    <Typography variant="h2">Influencer Campaign</Typography>
                    <Typography variant="medium_roboto">Jockey</Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={400}
                                    className="image-skeleton"
                                />
                                <img
                                    src={Jockey_image[0].img}
                                    alt="topimg"
                                    loading="lazy"
                                    style={{ width: "100%", height: "auto" }}
                                    onLoad={(e) => {
                                        e.target.previousSibling.style.display = "none";
                                        e.target.style.display = "block";
                                    }}
                                    onError={(e) => {
                                        e.target.previousSibling.style.display = "none";
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <div className="mt-2">
                        <Grid container spacing={1} columns={12}>
                            <Grid item xs={6} sm={6} md={6}>
                                <img
                                    src={`${Jockey_imageTop[0].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <img
                                    src={`${Jockey_imageTop[1].img}`}
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="mt-2">
                        <Grid container spacing={1} columns={12}>
                            {images
                                .filter((_, index) => index !== 0)
                                .map((item) => (
                                    <Grid item xs={4} sm={4} md={4}>
                                        <img
                                            src={`${item.img}`}
                                            style={{ width: "100%", height: "auto" }}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                </Box>
                <Divider
                    orientation="horizontal"
                    style={{ marginTop: "3rem", marginBottom: "3rem", width: "100%" }}
                />
            </Box>
        </motion.div>
    );
}
